import api from "@/libs/axios";

export default {
  async getList(params) {
    let response = await api.get("/admin/social-game/bot", { params });
    return response.data.data;
  },
  async get(params) {
    let response = await api.get("/admin/social-game/bot/detail", {
      params
    });
    return response.data.data || null;
  },
  async create(payload) {
    let response = await api.post("/admin/social-game/bot", payload);
    return response;
  },
  async import(payload) {
    let response = await api.post("/admin/social-game/bot/import", payload);
    return response;
  },
  async replace(payload) {
    let response = await api.post("/admin/social-game/bot/replace", payload);
    return response;
  },
  async update(payload) {
    let response = await api.put("/admin/social-game/bot", payload);
    return response;
  },
  async delete(payload) {
    let response = await api.delete("/admin/social-game/bot", {
      data: payload
    });
    return response;
  }
};
