<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Bot" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <b-row>
          <b-col cols="4">
            <h2 class="mb-2">Bot Management</h2>
          </b-col>
          <b-col cols="8">
            <div class="d-flex justify-content-end import mb-1">
              <b-button pill variant="outline-info">
                <a href="https://docs.google.com/spreadsheets/d/1P5fHWJ8l8ye_QK8aiJrjTaD1CKTFwD5LCZxoS1jttuA/edit?gid=0#gid=0"
                  target="_blank">?</a>
              </b-button>
              <label for="upload" class="btn btn-import btn-primary">
                <span>IMPORT</span>
                <input type="file" id="upload" ref="files" style="display: none" @change="handleFilesUpload">
              </label>
              <p class="rows-uploaded">{{ excelData.length + ' rows uploaded' }}</p>
              <b-button v-if="isReadyImport" variant="success" class="btn-add mr-1" size="sm"
                @click="updateLoadData">SAVE</b-button>
              <b-button v-if="isReadyImport" variant="success" class="btn-add" size="sm"
                @click="replaceData">REPLACE</b-button>
            </div>
          </b-col>
        </b-row>
        <data-create-popup title="Create Quiz" :schema="create_schema" :default-data="default_data"
          :create-data-fn="createItem" @create="$refs.group_list.getList()" />
        <data-table-ssr id="group_list" ref="group_list" :columns="fields" :get-list-fn="getList"
          :update_schema="create_schema" :edit-row-fn="updateItem" :delete-row-fn="deleteItem" />
      </section>
    </b-media>
  </div>
</template>

<script>
import service from "../service";
import XLSX from 'xlsx'
const VALID_FILE_EXTENSIONS = 'xlsx'
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Name', field: 'name', validate: { required: true } },
      { label: 'Avatar', field: 'avatar', validate: { required: true } },
    ]
  },
];

const fields = [
  { label: 'Name', field: 'name' },
  { label: 'Avatar', field: 'avatar' },
];
export default {
  data() {
    return {
      create_schema,
      fields,
      excelData: [],
      isLoading: false,
      default_data: { name: null, avatar: null },
      isReadyImport: false
    };
  },
  watch: {
    current_page() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList({ limit, page, query }) {
      let response_data = await service.getList({
        query: JSON.stringify({ ...query }),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    async updateItem(row) {
      await service.update({ data: JSON.stringify(row) });
    },
    async deleteItem({ _id }) {
      await service.delete({ id: _id });
    },
    handleFilesUpload(e) {
      var input = e.target;
      var reader = new FileReader();
      if (!input.files[0] || !this.checkValidFileExtension(input.files[0].name)) {
        return this.$store.dispatch("pushErrorNotify", {
          text: "File upload is invalid!",
        })
      }
      reader.onload = () => {
        var fileData = reader.result;
        var wb = XLSX.read(fileData, { type: 'binary' });
        wb.SheetNames.forEach((sheetName) => {
          var rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
          this.excelData = rowObj
        })
      };
      reader.readAsBinaryString(input.files[0]);
      this.isReadyImport = true;
    },

    checkValidFileExtension(fileName) {
      if (!fileName) { return false }
      return fileName.substr(fileName.length - VALID_FILE_EXTENSIONS.length, VALID_FILE_EXTENSIONS.length).toLowerCase() === VALID_FILE_EXTENSIONS
    },

    async updateLoadData() {
      this.isLoading = true
      const dataUpload = this.excelData.map(usr => {
        return {
          _id: usr._id || null,
          name: usr['Name'],
          avatar: usr['Avatar'],
        }
      })
      if (!dataUpload.length) {
        this.isLoading = false
        return this.$store.dispatch("pushErrorNotify", {
          text: "users data is empty!",
        })
      }

      let res = await service.import(dataUpload);
      this.isLoading = false
      if (res) {
        this.$store.dispatch("pushSuccessNotify", { text: "Import Successfully!" });
        this.excelData = [];
      }
      this.$router.go()
    },
    async replaceData() {
      this.isLoading = true
      const dataUpload = this.excelData.map(usr => {
        return {
          name: usr['Name'],
          avatar: usr['Avatar'],
        }
      })
      if (!dataUpload.length) {
        this.isLoading = false
        return this.$store.dispatch("pushErrorNotify", {
          text: "users data is empty!",
        })
      }

      let res = await service.replace(dataUpload);
      this.isLoading = false
      if (res) {
        this.$store.dispatch("pushSuccessNotify", { text: "Replace Successfully!" });
        this.excelData = [];
      }
      this.$router.go()
    }
  },
};
</script>
<style lang="scss" scoped>
.btn-import {
  margin-left: 5px;
  display: table;
  margin-bottom: 0;

  span {
    display: table-cell;
  }
}

.rows-uploaded {
  margin: auto 5px;
  padding: 10px 5px;
  border: solid 1px;
  border-radius: 10px;
  font-weight: 800;
}
</style>
